import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import BlankScreen from '../components/Loading'
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';

import { userActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { validatePassword } from '../helpers/passwordValidation.js';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        confirmEmail: '',
        confirmPassword: '',
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { user } = this.state;
    user.username = user.email; //username and email will be the same
    const { dispatch } = this.props;

    //validation
    const invalid = validatePassword(user.password, "");
    if (user.confirmEmail != user.email) {
      invalid.push({ reason: "Email confirmation must match", code: 101 })
    }
    if (user.confirmPassword != user.password) {
      invalid.push({ reason: "Password confirmation must match", code: 102 })
    }
    if (invalid.length === 0) {
      user.realm = user.firstName + ":" + user.lastName;
      this.setState({ submitted: true, invalid: false });
      dispatch(userActions.register(user)).then(() => {
        this.setState({ redirectSomewhere: '/login' });
      })
    } else {
      this.setState({ invalid: invalid })
    }
  }

  revealPasswordToggle(input) {
    let passwordInput = document.getElementById("passwordInput" + input.toString());
    let eyeButton = document.getElementById("revealPasswordButton" + input.toString());

    //console.log(eyeButton.className);
    //console.log(passwordInput);

    if (eyeButton.className === "toggle-password-register ml-2 fa fa-eye") {
      eyeButton.className = "toggle-password-register ml-2 fa fa-eye-slash"
      passwordInput.type = "input"
    }
    else {
      eyeButton.className = "toggle-password-register ml-2 fa fa-eye"
      passwordInput.type = "password"
    }
  }

  render() {
    const { registering, brandings } = this.props;
    const { user, submitted,redirectSomewhere } = this.state;

    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    let style = { color: brandings.branding && brandings.branding.TextColour }
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, "register_header", "New User Registration")}
          subtitle={brandingMessage(brandings, "register_subtitle", "Please register your details below - make sure you use your company email address (not your personal one!)")}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-12" style={style}>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChange} />
                  {submitted && !user.firstName &&
                    <div className="help-block">First Name is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChange} />
                  {submitted && !user.lastName &&
                    <div className="help-block">Last Name is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.email ? ' has-error' : '')}>
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange} />
                  {submitted && !user.email &&
                    <div className="help-block">Email address is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && (user.confirmEmail != user.email) ? ' has-error' : '')}>
                  <label htmlFor="email">Confirm Email</label>
                  <input type="email" className="form-control" name="confirmEmail" value={user.confirmEmail} onChange={this.handleChange} />
                  {submitted && (user.confirmEmail != user.email) &&
                    <div className="help-block">Email confirmation must match</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                  <label htmlFor="password">Password</label>
                  <input id="passwordInput1" type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                  <button id="revealPasswordButton1" className="toggle-password-register ml-2 fa fa-eye" type="button" onClick={() => { this.revealPasswordToggle(1) }}></button>
                  {submitted && !user.password &&
                    <div className="help-block">Password is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && (user.confirmPassword != user.password) ? ' has-error' : '')}>
                  <label htmlFor="password">Confirm Password</label>
                  <input id="passwordInput2" type="password" className="form-control" name="confirmPassword" value={user.confirmPassword} onChange={this.handleChange} />
                  <button id="revealPasswordButton2" className="toggle-password-register ml-2 fa fa-eye" type="button" onClick={() => { this.revealPasswordToggle(2) }}></button>
                  {submitted && (user.confirmPassword != user.password) &&
                    <div className="help-block">Password confirmation must match</div>
                  }
                </div>
                <div className="form-group">
                  <button className="btn btn-primary">Register</button>
                  {registering &&
                    <img alt="Registering..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
                  <Link to="/login" className="btn btn-link">Cancel</Link>
                  {this.state.invalid &&
                    <div className="alert alert-warning" style={{ marginTop: 10 }} role="alert">
                      {this.state.invalid.map(reason => (
                        <div> {reason.reason}</div>
                      ))}

                    </div>
                  }
                </div>
                <div className="">
                  <p> By using this site you agree with the full <a href="http://mi-crow.com/licenses/">terms of licence </a></p>
                </div>
              </form>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12" style={style}>
              <h2 style={style}> Remember! </h2>


              <p style={style}>Your password needs to be a minimum of 8 characters</p>
              <p style={style}>It must contain at least:
                <ul>
                  <li>1 Uppercase letter</li>
                  <li>1 Lowercase letter</li>
                  <li>1 Number</li>
                  <li>1 Symbol</li>
                </ul>
              </p>
              <p style={style}>The more characters you use, the stronger your password will be.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering, user } = state.registration;
  const { brandings } = state;
  return {
    registering, user, brandings
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export default connectedRegisterPage;
