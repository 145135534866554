import React, { Component } from 'react';
import Section from './Section';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {browserHistory} from 'react-router';
import { catalogueitemActions } from '../actions';

class Playlist extends Component {
  constructor(props) {

    super(props);
    this.state = {
      videoID: props.videoID
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      videoID: newProps.videoID
    });
  }

  //Top part of recursive menu, see section for logic
  render() {
    return (
      <div className="playlist">
        <ul className="dropdown-menu outer-dropdown show" key="playlist-container">
            <Section playlist={this} data={this.props.data} accessedData={this.props.accessedData} title={this.props.Name} id={this.props.data.id} subscriptionID={this.props.subscriptionID} playlistID={this.props.from} videoID={this.state.videoID} userExtensionId={this.props.userExtensionId}></Section>
        </ul>
      </div>
    )
  }
}
export default Playlist
