import React from 'react';
import { useParams } from 'react-router-dom';
import VideoRedirect from "./VideoRedirect";

function WrappedVideoRedirect() {
  //console.log('params', useParams());
  const { c, v, branding } = useParams();
  return (
    <VideoRedirect c={c} v={v} branding={branding}/>
  );
}

export default WrappedVideoRedirect;