import React, { Component } from 'react';
import ContentItem from './ContentItem'
import { orderBy } from '../helpers/orderBy.js'

class Section extends Component {
  constructor(props) {
    // console.log(props);
    // let test = props.data.contents.filter(content =>{return content.id = props.videoID});
    // console.log(test);
    super(props);
    this.state = {
      playlistID: props.playlistID,
      title: props.title,
      hierarchy: props.data,
      videoID: props.videoID,
      playlist: props.playlist,
      accessedData: props.accessedData
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      videoID: newProps.videoID
    });
  }

  //Is this is another menu section or a video
  //if section, create another section object
  //if video, create a content item
  sectionOrVideo(h){
    if(h.children instanceof Array){
      h.children = orderBy("SeqNo", h.children)
      return(
        <li className="dropdown-submenu">
          <a className="test">
            <i className="fa fa-caret-right"></i>
            {" "+h.Name}
          </a>
          <ul className="dropdown-menu">
            { h.children.map((d) =>
              { return <Section playlist={this.state.playlist} key={d.id} subscriptionID={this.props.subscriptionID} videoID={this.state.videoID} title={d.title} data={d} accessedData={this.state.accessedData} playlistID={this.props.playlistID} userExtensionId={this.props.userExtensionId}/>}
            )}
          </ul>
        </li>)
    } else {
      if(h.contents) {
        h.contents = orderBy("SeqNo", h.contents)
        return (
          h.contents.map((c) => {return (
            <ContentItem playlist={this.state.playlist} key={c.id} subscriptionID={this.props.subscriptionID} videoID={this.state.videoID} data={c} accessedData={this.state.accessedData} playlistID={this.props.playlistID} userExtensionId={this.props.userExtensionId}/>
          ) })
        )
      } else {
        return (
          <ContentItem playlist={this.state.playlist} subscriptionID={this.props.subscriptionID} videoID={this.state.videoID} data={h} accessedData={this.state.accessedData} playlistID={this.props.playlistID} userExtensionId={this.props.userExtensionId}/>
        )
      }
    }
  }
  render() {

    return (this.sectionOrVideo(this.state.hierarchy))
  }
}

export default Section
