import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from 'react-redux';
import 'custom-event-polyfill';

import { settings } from './helpers/settings.js';
import { applyBranding } from './helpers/applyBranding.js';
import { brandingActions } from './actions';

import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';

import {_404 ,Home, WrappedWatch, WrappedLogin, Register, PasswordReset, SetPassword, FAQ, OutOfCredits, CourseWatched, Analytics,
  WrappedVideoRedirect, ViewHistory, LicenseAcceptanceForm, Failure, Success, WrappedB, WrappedSearch} from './pages';

class App extends Component {
  constructor(props) {
    super(props);
    const pathname = window.location.pathname;
    if (pathname !== "/login" && pathname !== "/success" && !pathname.startsWith("/auth")) {
      localStorage.setItem("firstLoad", pathname);
    }
  }

  componentDidMount() {
    this.handleBranding();
  }

  handleBranding() {
    const host = window.location.host;
    let subdomain = host.split('.')[0];

    if (!host.includes('microw-courses.com')) {
      subdomain = host;
    }
    else {
      subdomain = (subdomain.includes(":") || subdomain.includes("test")) ? "www" : subdomain
    }

    if (subdomain != "www") {
      this.props.dispatch(brandingActions.getByName(subdomain)).then((success) => {
        if (!success.brandings || (success.brandings && success.brandings.failed)) {
          this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
          applyBranding(settings.brandingDefaults, document)
        } else {
          applyBranding(success.brandings, document)
        }
      })
    } else {
      if (this.props.authentication && this.props.authentication.loggedIn) {
        this.props.dispatch(brandingActions.getByUserId(this.props.authentication.user.userId)).then((success) => {
          if (!success.brandings || (success.brandings && success.brandings.failed)) {
            this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
            applyBranding(settings.brandingDefaults, document)
          } else {
            applyBranding(success.brandings, document)
          }
        })
      } else {
        this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
        applyBranding(settings.brandingDefaults, document)
      }
    }
  }

  componentWillUnmount() {
  }
  

  //TODO move alert messages to somewhere more visible
  render() {
    const { alert, brandings } = this.props;
    const isAuthenticated = localStorage.getItem('user');
    
    console.log("branding", brandings);
    return (
      <div>
        <LicenseAcceptanceForm />
        <div className="mainContainer">
          <Routes>
            <Route exact path="/" element={<PrivateRoute isAuthenticated={isAuthenticated} > <Home /> </PrivateRoute>} />
            <Route exact path="/success" element={<Success />} />
            <Route exact path="/failure" element={<Failure />} />
            <Route exact path="/b/:branding?" element={<WrappedB />} />
            <Route exact path="/login/:isReset?" element={<WrappedLogin />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<PasswordReset />} />
            <Route exact path="/SetPassword" element={ <PrivateRoute isAuthenticated={isAuthenticated} > <SetPassword /> </PrivateRoute> } />
            <Route exact path="/FAQ" element={<FAQ />} />
            <Route exact path="/CourseWatched" element={<CourseWatched />} />
            <Route exact path="/Analytics" element={ <PrivateRoute isAuthenticated={isAuthenticated} > <Analytics /> </PrivateRoute> } />
            <Route exact path="/OutOfCredits" element={<OutOfCredits />} />
            <Route exact path="/watch/:subscriptionID/:playlistID/:videoID?" element={<PrivateRoute isAuthenticated={isAuthenticated} > <WrappedWatch /> </PrivateRoute>}/>
            <Route exact path="/search/:text?" element={ <PrivateRoute isAuthenticated={isAuthenticated} > <WrappedSearch /> </PrivateRoute> } />
            <Route exact path="/v/:c/:v/:branding?" element={<WrappedVideoRedirect />} />
            <Route exact path="/history" element={ <PrivateRoute isAuthenticated={isAuthenticated} > <ViewHistory /> </PrivateRoute> } />
            <Route path="*" element={<_404 />} />
          </Routes>
          {alert.message &&
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-8 col-sm-12">
                  <div className={`alert ${alert.type}`}>{
                    alert.message.split("\n").map(line => (<div>{line}</div>))
                  }</div>
                </div>
              </div>
            </div>
          }
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, brandings } = state;



  if (brandings.branding) {
    if (brandings.branding.showSnow === true) {
      let showSnowCookie = sessionStorage.getItem('showSnow');
      if (showSnowCookie !== 'false') {
        document.getElementById('snow-overlay').style.display = 'block';
        setTimeout(() => {
          document.getElementById('snow-overlay').style.display = 'none';
          sessionStorage.setItem('showSnow', 'false');
        }, 15000);
      }
    }
  }

  return {
    alert, authentication, brandings
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default connectedApp;
