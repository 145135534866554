import React, { Component } from 'react';
import { WistiaProvider, WistiaPlayer } from '@wistia/react-embeds';

class TestWistiaPlayer extends Component {
  constructor(props) {
    super(props);

    // this.onReadyFunction = this.onReadyFunction.bind(this);
  }

  // onReadyFunction(e){
  //   console.log(Date.now(),e._readyTime);
  //   console.log("on ready", e);

  //   window._wq = window._wq || [];
  //   _wq.push({
  //     id: this.props.IntroCode,
  //     options: {
  //       plugin: {
  //         videoThumbnail: {
  //           on: true,
  //           hashedId: this.props.code,
  //           trimStart: 0,
  //           trimEnd: 1,
  //           async: false,
  //         }
  //       }
  //     }
  //   });
  // }

  render() {
    const { IntroCode, OutroCode, ShouldUseBrandedIntro, code } = this.props;
    //console.log(this.state);
    //console.log("brandings", this.props);
    //key is essential as it will make the Wistia component get dropped and recreated on props change, 
    //which is the only way to get it to change video on route change.

    //It is using a playListLinks method to insert intro and outro codes https://docs.wistia.com/docs/embed-links#simple-video-replacement-example
    //In simple terms make sure that the first video (the intro) is also included as a href, and then any videos that need to show after it 
    //(main content and then outro) follow as links, and it will find them automatically
    if (!ShouldUseBrandedIntro) {
      return (
        <WistiaProvider>
          <WistiaPlayer hashedId={code} />
        </WistiaProvider>
      )
    }
    return (
      <div key={code}>
        <WistiaProvider>
          <WistiaPlayer id="playlist1" hashedId={IntroCode} playlistLinks="auto"/>
        </WistiaProvider>
        <a href={"#wistia_" + IntroCode + "?transition=none"}></a><br />
        <a href={"#wistia_" + code + "?transition=none"}></a><br />
        <a href={"#wistia_" + OutroCode + "?transition=none"}></a><br />
      </div>
    );

  };
}
export default TestWistiaPlayer
